.c-request-demo {
    $c: &;

    @apply .relative;

    &__inner {
        @apply .relative .bg-primary .p-10 .pb-12;
    }

    &__form {
        @apply .z-50 .relative;

        .c-form {
            &__row {
                @apply .mb-5;

                &:last-child {
                    @apply .mb-0;
                }
            }

            &__column {
                @apply .mb-0;
            }

            .c-privacy-disclaimer {
                @apply .text-xs;
            }
        }

        .c-form-field {
            &__label {
                @apply .text-secondary .text-xs .uppercase .tracking-1px .mt-0;
            }

            &__input {
                @apply .bg-transparent .border-b .border-secondary-40 .pl-0 .pr-0 .text-white;

                &:focus {
                    @apply .border-secondary;
                }
            }

            &--text,
            &--email {
                .c-form-field {
                    &__input {
                        @apply .h-8;
                    }
                }
            }
        }
    }

    &:before,
    &:after {
        content: '';

        @apply .absolute;
    }

    &:before {
        top: -80px;
        right: -65px;

        width: 246px;
        height: 164px;

        background-image: url('/images/splash-top.png');
    }

    &:after {
        bottom: -46px;
        left: -34px;

        width: 215px;
        height: 157px;

        background-image: url('/images/splash-bottom.png');

        @apply .z-30;
    }
}
