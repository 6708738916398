.c-search-box {
    height: 50px;

    $c: &;

    @apply .bg-quinary;

    @screen md {
        min-width: 375px;
    }

    &__form {
        @apply .flex .w-full .h-full;
    }

    &__input {
        @apply .bg-transparent .h-full .flex-grow .px-6 .text-sm .text-secondary .italic;
    }

    &__submit {
        @apply .h-full .flex-no-shrink .flex .items-center .px-6 .text-primary;

        &:hover,
        &:active {
            .c-icon {
                @apply .text-tertiary;
            }
        }
    }

    &--mobile {
        @apply .bg-transparent .border .border-tertiary;

        #{$c} {
            &__submit {
                @apply .text-tertiary;
            }
        }
    }
}
