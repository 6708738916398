.c-tabs {
    $c: &;

    &__nav {
        @screen md {
            @apply .bg-secondary-20;
        }
    }

    &__content {
        @apply .pt-12;
    }

    &__pane {
        @apply .hidden;

        &.is-open {
            @apply .block;
        }
    }
}
