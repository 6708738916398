.c-preview {
    $c: &;

    @apply .text-0;

    &__figure {
        box-shadow: -10px 9px 21px 0 rgba(128,152,213,.07);

        @apply .bg-white .w-full .p-1.5 .flex .items-center .justify-center;

        &:hover,
        &:active {
            box-shadow: -10px 9px 21px 0 rgba(128,152,213,.12);
        }
    }

    &__image {
        @apply .h-full .object-contain .object-center;
    }

    &__body {
        @apply .mt-4;
    }

    &--category {
        #{$c} {
            &__figure {
                height: 240px;
            }
        }
    }

    &--product,
    &--realization {
        #{$c} {
            &__figure {
                height: 160px;
            }
        }
    }

    &--realization {
        #{$c} {
            &__figure {
                @apply .p-0;
            }

            &__image {
                @apply .object-cover;
            }
        }
    }
}
