.c-text {
    $c: &;

    a {
        @apply .text-primary .font-bold .border-b .border-primary-30;

        &:hover,
        &:active {
            @apply .border-primary-80;
        }
    }

    p {
        @apply .mb-8;

        &:last-child {
            @apply .mb-0;
        }
    }

    ul {
        @apply .mb-8;

        &:last-child {
            @apply .mb-0;
        }

        li {
            @apply .relative .pl-3 .mb-1;

            &:before {
                top: 6px;
                left: 0;

                width: 4px;
                height: 13px;

                content: '';

                background-image: url('/images/bullet.svg');

                @apply .absolute;
            }

            &:last-child {
                @apply .mb-0;
            }
        }
    }

    h3 {
        @apply .mb-2;
    }

    h4 {
        @apply .mb-1.5;
    }
}
