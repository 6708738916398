.c-nav {
    $c: &;

    @apply .text-0 .inline-block;

    &__list {
        @apply .inline-block;
    }

    &__item {
        @apply .relative .inline-block;
    }

    &__link {
        @apply .inline-block .transition;
    }

    &__label {
        @apply .inline-block .transition;
    }

    &--primary {
        #{$c} {
            &__list {
                @apply .-ml-10;
            }

            &__item {
                @apply .pl-10;

                &.is-active {
                    #{$c} {
                        &__label {
                            @apply .text-primary .border-primary;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary .border-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-base .text-primary-60 .border-b .border-transparent;
            }
        }
    }

    &--secondary {
        #{$c} {
            &__list {
                @apply .-ml-4;
            }

            &__item {
                @apply .pl-4;

                // &.is-active {
                //     #{$c} {
                //         &__label {
                //             @apply .border-white-30;
                //         }
                //     }
                // }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-white-30;
                        }
                    }
                }
            }

            &__label {
                @apply .text-white .text-sm .border-b .border-transparent;
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .-mb-0.5;
            }

            &__item {
                @apply .block .pb-0.5;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-white;
                        }
                    }
                }
            }

            &__label {
                @apply .text-secondary .text-base;
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
            }

            &__item {
                @apply .pl-2 .ml-2;

                &:before {
                    top: 50%;
                    left: 0;

                    content: '';
                    transform: translate(-50%, -50%);

                    @apply .absolute .w-1 .h-1 .bg-white .rounded-full;
                }

                &:first-child {
                    @apply .pl-0 .ml-0;

                    &:before {
                        @apply .hidden;
                    }
                }

                @screen md {
                    &:first-child {
                        @apply .pl-2 .ml-2;

                        &:before {
                            @apply .inline-block;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-white-80;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xs .text-white .border-b .border-white-30 .font-bold;
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .-ml-8 .-mb-8;
            }

            &__item {
                @apply .pl-8 .pb-8;
            }

            &__link {
                @apply .text-white;

                &:hover,
                &:active {
                    .c-icon {
                        @apply .text-tertiary;
                    }
                }
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__list {
            }

            &__item {
                @apply .pr-2 .mr-2;

                &:after {
                    top: 50%;
                    right: 0;

                    width: 4px;
                    height: 9px;

                    content: '';
                    transform: translate(50%, -50%);

                    background-image: url('/images/bullet--alt.svg');

                    @apply .absolute .bg-no-repeat .bg-cover .bg-center;
                }

                &:last-child {
                    #{$c} {
                        &__label {
                            @apply .text-secondary .font-normal;
                        }
                    }

                    &:after {
                        @apply .hidden;
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-primary-20;
                        }
                    }
                }
            }

            &__label {
                @apply .text-1xs .font-bold .text-primary .border-b .border-transparent;
            }
        }
    }

    &--tabs {
        @apply .w-full;

        #{$c} {
            &__list {
                @apply .w-full .-mb-4;

                @screen md {
                    @apply .-mb-0;
                }
            }

            &__item {
                @apply .block .w-full .pb-4;

                @screen md {
                    @apply .pb-0;
                }

                &.is-active {
                    #{$c} {
                        &__link {
                            @apply .border-primary;

                            @screen md {
                                @apply .bg-primary;
                            }
                        }

                        &__label {
                            @apply .text-primary;

                            @screen md {
                                @apply .text-white;
                            }
                        }

                        &__icon {
                            @apply .text-primary;
                        }
                    }
                }

                @screen md {
                    @apply .inline-block .w-auto;
                }
            }

            &__link {
                @apply .pb-4 .w-full .flex .items-center .border-b .border-secondary;

                @screen md {
                    @apply .py-3 px-6 .w-full .border-0;
                }

                &:hover,
                &:active {
                    @apply .border-primary;

                    @screen md {
                        @apply .bg-primary;
                    }

                    #{$c} {
                        &__label {
                            @apply .text-primary;

                            @screen md {
                                @apply .text-white;
                            }
                        }

                        &__icon {
                            @apply .text-primary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-base .text-secondary .flex-grow;
            }

            &__icon {
                @apply .text-secondary .flex-no-shrink;

                @screen md {
                    @apply .hidden;
                }
            }
        }
    }
}
