.c-header {
    $c: &;

    @apply .relative;

    &__top {
        height: 50px;

        @apply .bg-primary;

        @screen lg {
            @apply .bg-tertiary;
        }

        &-inner {
            @apply .relative .bg-primary .h-full;

            &:before {
                right: 100%;

                width: 1000px;

                content: '';

                @apply .absolute .bg-primary .h-full;
            }
        }
    }

    &__bottom {
        @apply .bg-white .pt-6 .pb-8;

        @screen md {
            @apply .pt-8 .pb-10;
        }
    }

    &:before {
        bottom: -54px;
        left: -10px;

        width: 230px;
        height: 79px;

        content: '';

        background-image: url('/images/waves-header--sm.svg');

        @apply .bg-no-repeat .bg-cover .bg-center .absolute;

        @screen md {
            bottom: -100px;
            left: -80px;

            width: 453px;
            height: 155px;

            background-image: url('/images/waves-header.svg');
        }

        @screen xl {
            left: 0;
        }
    }
}
