.c-social-feed-item {
    $c: &;

    @apply .bg-quinary .p-8;

    &__figure {
        @apply .block .mb-6 .relative;
    }

    &__image {
        @apply .w-full;
    }

    &__icon {
        top: 24px;
        right: -8px;

        width: 56px;
        height: 56px;

        @apply .absolute .bg-primary .flex .items-center .justify-center;

        .c-icon {
            @apply .text-white;
        }

        &:before {
            top: -8px;
            right: 0;

            width: 0;
            height: 0;

            content: '';

            border-width: 0 8px 8px 0;
            border-style: solid;
            border-color: transparent transparent #002646 transparent;

            @apply .absolute;
        }
    }

    &__body {
    }

    &__caption {
        @apply .text-primary .text-base;
    }

    &__date {
        @apply .inline-block .text-xsm .text-secondary;
    }
}
