.c-form-field {
    $c: &;

    &__label-wrapper {
        min-width: 100px;

        @apply .flex-no-shrink .mb-2;

        @screen lg {
            @apply .mb-0;
        }
    }

    &__input-wrapper {
        @apply .flex-grow;
    }

    &__label {
        @apply .relative .inline-block .mt-3 .text-base .text-primary;

        &.is-required {
            &:after {
                top: 0;
                left: 100%;

                content: '*';

                @apply .absolute .pl-1 .text-base .text-primary;
            }
        }
    }

    &__input {
        @apply .w-full .text-base .leading-normal .text-secondary .p-3 .transition .border-b .border-transparent;

        &:focus {
            border-bottom-color: config('colors.secondary');
        }
    }

    &__errors {
        @apply .text-sm .text-error .mt-1.5;
    }

    &--text,
    &--email {
        #{$c} {
            &__input {
                @apply .h-12;
            }
        }
    }

    &--checkbox,
    &--mailing_list {
        #{$c} {
            &__input {
                @apply .hidden;

                &:checked {
                     + #{$c} {
                        &__label {
                            .c-checkbox {
                                &__input {
                                    @apply .bg-primary;

                                    .c-icon {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__label {
                @apply .mt-8;
            }
        }
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;
            }
        }
    }
}
