.c-logo {
    $c: &;

    @apply .inline-block;

    svg {
        width: 158px;

        @apply .h-auto;

        @screen md {
            width: 240px;
        }
    }
}
