.c-footer {
    $c: &;

    @apply .relative .bg-primary .py-12;

    &__top,
    &__bottom {
        @apply .relative .z-10;
    }

    &__top {
        @apply .border-b .border-white-20 .pb-12 .mb-12;

        @screen md {
            @apply .pb-10 .mb-16;
        }
    }

    &__bottom {
        .c-link {
            &--secondary {
                &:hover,
                &:active {
                    .c-link {
                        &__label {
                            @apply .text-white;
                        }
                    }
                }
            }

            &--quaternary {
                .c-link {
                    &__label {
                        @apply .border-white-30;
                    }
                }

                &:hover,
                &:active {
                    .c-link {
                        &__label {
                            @apply .border-white-80;
                        }
                    }
                }
            }
        }
    }

    &:after {
        @screen lg {
            width: 611px;
            height: 166px;

            content: '';

            background-image: url('/images/waves-footer.svg');

            @apply .absolute .bg-no-repeat .bg-center .bg-cover .pin-b .pin-r;
        }
    }
}
