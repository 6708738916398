.c-checkbox {
    $c: &;

    @apply .flex .items-center;

    &__input {
        @apply .flex-no-shrink .relative .bg-transparent .border .border-primary .cursor-pointer .mr-3 .w-4 .h-4 .flex .items-center .justify-center .text-white .transition;

        .c-icon {
            opacity: 0;
        }
    }

    &__label {
        @apply .flex-grow .cursor-pointer;

        a {
            color: inherit;

            @apply .border-b .border-grey-darker .pb-0.5;

            &:hover,
            &:active {
                @apply .border-grey-dark;
            }
        }
    }
}
