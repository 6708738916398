.c-social-share {
    $c: &;

    &__list {
        @apply .flex .-ml-1;
    }

    &__item {
        @apply .pl-1;

        &--facebook {
            #{$c} {
                &__link {
                    @apply .bg-facebook;

                    &:hover,
                    &:active {
                        @apply .bg-facebook-90;
                    }
                }

                &__icon {
                    .c-icon {
                        width: 6px;
                        height: 11px;
                    }
                }
            }
        }

        &--linkedin {
            #{$c} {
                &__link {
                    @apply .bg-linkedin;

                    &:hover,
                    &:active {
                        @apply .bg-linkedin-90;
                    }
                }

                &__icon {
                    .c-icon {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    &__link {
        @apply .w-6 .h-6 .rounded-full .flex .items-center .justify-center;
    }

    &__icon {
        @apply .text-white;
    }
}
