.c-table {
    $c: &;

    @apply .w-full .overflow-x-auto;

    @screen sm {
        @apply .overflow-x-hidden .table;
    }

    thead {
        tr {
        }

        th {
            @apply .text-primary .font-bold .text-base .bg-secondary-20 .border .border-quinary;
        }
    }

    tbody {
        td {
            @apply .text-primary .bg-white .border .border-quinary;

            &:nth-child(2) {
                @apply .text-secondary;
            }
        }
    }

    td,
    th {
        @apply .py-4 .px-5;
    }
}
