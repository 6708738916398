.c-newsletter-subscribe {
    $c: &;

    @apply .text-0 .w-full;

    @screen lg {
        min-width: 440px;
    }

    &__form {
        @apply .relative .w-full .h-full;

        @screen md {
            @apply .flex;
        }
    }

    &__input {
        height: 58px;

        @apply .bg-transparent .px-6 .text-base .text-white .italic .border .border-quinary .leading-none .transition .w-full .mb-2;

        &::placeholder {
            @apply .text-secondary;
        }

        @screen md {
            @apply .flex-grow .w-auto .mb-0;
        }
    }

    &__submit {
        height: 58px;

        @apply .bg-quinary .flex .items-center .px-6 .text-primary .text-base .justify-center;

        &:hover,
        &:active {
            @apply .bg-quinary-90;
        }

        @screen md {
            @apply .flex-no-shrink;
        }
    }

    .ff-has-error {
        #{$c} {
            &__input {
                @apply .bg-error;
            }
        }
    }

    .ff-form-success {
        top: 100%;

        @apply .absolute .text-xs .text-success .mt-1;
    }
}
