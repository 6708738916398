.c-gallery {
    $c: &;

    @apply .relative .text-0;

    &__link {
        @apply .w-full .transition;

        &:hover,
        &:active {
            @apply .opacity-90;
        }
    }

    &__indicator {
        @apply .absolute .pin-r .pin-b .bg-primary .text-white .text-xbase .w-10 .h-10 .flex .items-center .justify-center;
    }

    &--product {
        #{$c} {
            &__list {
            }

            &__item {
            }

            &__link {
                @apply .flex .items-center .justify-center .bg-white;
            }

            &__image {
                height: 370px;

                @apply .object-contain .object-center;
            }
        }
    }
}
