// -----------------------------------------------------------------------------
// elements/input
// -----------------------------------------------------------------------------

input,
textarea {
    padding: 0;

    border-radius: 0;

    -webkit-appearance: none;


    &:focus {
        @apply .outline-none;
    }

    &::placeholder {
        @apply .text-secondary .opacity-100;
    }
}

textarea {
    resize: none;
}

select {
    -webkit-border-radius: 0;

    -webkit-appearance: none;

    &:focus {
        @apply .outline-none;
    }
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
