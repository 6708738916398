.c-link {
    $c: &;

    @apply .inline-flex .items-center;

    &__icon {
        @apply .inline-block .flex-no-shrink .leading-0 .transition;
    }

    &__label {
        @apply .inline-block .flex-grow .text-base .leading-normal .border-b .border-transparent .transition;
    }

    &--primary {
        #{$c} {
            &__icon {
                @apply .text-primary;
            }

            &__label {
                @apply .text-primary .font-bold .border-primary-30;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    .c-icon {
                    }
                }

                &__label {
                    @apply .border-primary-80;
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__icon {
                @apply .text-secondary;
            }

            &__label {
                @apply .text-secondary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    .c-icon {
                        @apply .text-primary;
                    }
                }

                &__label {
                    @apply .text-primary .border-primary-30;
                }
            }
        }

        &#{$c} {
            &--download {
                @apply .inline;
                
                #{$c} {
                    &__icon {
                        .c-icon {
                            @apply .text-tertiary;
                        }
                    }

                    &__label {
                        @apply .inline .border-secondary-30;
                    }
                }

                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-primary .border-primary-30;
                        }
                    }
                }
            }
        }
    }

    &--tertiary {
        #{$c} {
            &__icon {
                @apply .text-tertiary;
            }

            &__label {
                @apply .text-tertiary .border-tertiary-30;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    .c-icon {
                    }
                }

                &__label {
                    @apply .border-tertiary-80;
                }
            }
        }
    }

    &--quaternary {
        #{$c} {
            &__icon {
                @apply .text-white;
            }

            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .border-white-30;
                }
            }
        }
    }

    &--quinary {
        #{$c} {
            &__icon {
                @apply .text-primary;
            }

            &__label {
                @apply .text-primary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .border-primary-30;
                }
            }
        }
    }

    &.has-icon-before {
        #{$c} {
            &__icon {
                @apply .mr-2;
            }
        }
    }

    &.has-icon-after {
        #{$c} {
            &__icon {
                @apply .ml-2;
            }
        }
    }
}
