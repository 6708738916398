/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
/* purgecss start ignore */
@tailwind preflight;
/* purgecss end ignore */

/**
  * This injects any component classes registered by plugins.
  *
  * If using `postcss-import`, use this import instead:
  *
  * @import "tailwindcss/components";
  */
@tailwind components;

/**
  * Here you would add any of your custom component classes; stuff that you'd
  * want loaded *before* the utilities so that the utilities could still
  * override them.
  *
  * Example:
  *
  * .btn { ... }
  * .form-input { ... }
  *
  * Or if using a preprocessor or `postcss-import`:
  *
  * @import "components/buttons";
  * @import "components/forms";
  */

/* purgecss start ignore */
@import "01-generics/_base.scss";
@import "01-generics/_fonts.scss";
@import "02-vendors/_hamburgers.scss";
@import "02-vendors/_magnific-popup.scss";
@import "02-vendors/_photoswipe.scss";
@import "03-elements/_body.scss";
@import "03-elements/_button.scss";
@import "03-elements/_heading.scss";
@import "03-elements/_input.scss";
@import "03-elements/_link.scss";
@import "03-elements/_list.scss";
@import "04-components/_button.scss";
@import "04-components/_checkbox.scss";
@import "04-components/_container.scss";
@import "04-components/_content-builder.scss";
@import "04-components/_content.scss";
@import "04-components/_cookie-consent.scss";
@import "04-components/_dibo-logo.scss";
@import "04-components/_figure.scss";
@import "04-components/_footer.scss";
@import "04-components/_form-field.scss";
@import "04-components/_form.scss";
@import "04-components/_gallery.scss";
@import "04-components/_header.scss";
@import "04-components/_icon.scss";
@import "04-components/_link.scss";
@import "04-components/_logo.scss";
@import "04-components/_mobile-nav-handle.scss";
@import "04-components/_mobile-nav.scss";
@import "04-components/_nav.scss";
@import "04-components/_newsletter-subscribe.scss";
@import "04-components/_pagination.scss";
@import "04-components/_preview.scss";
@import "04-components/_request-demo.scss";
@import "04-components/_search-box.scss";
@import "04-components/_secondary-menu.scss";
@import "04-components/_social-feed-item.scss";
@import "04-components/_social-feed.scss";
@import "04-components/_social-share.scss";
@import "04-components/_table.scss";
@import "04-components/_tabs.scss";
@import "04-components/_text.scss";
@import "04-components/_usp.scss";
@import "04-components/_usps.scss";
@import "04-components/_video.scss";
/* purgecss end ignore */

/**
  * This injects all of Tailwind's utility classes, generated based on your
  * config file.
  *
  * If using `postcss-import`, use this import instead:
  *
  * @import "tailwindcss/utilities";
  */
@tailwind utilities;

/**
  * Here you would add any custom utilities you need that don't come out of the
  * box with Tailwind.
  *
  * Example :
  *
  * .bg-pattern-graph-paper { ... }
  * .skew-45 { ... }
  *
  * Or if using a preprocessor or `postcss-import`:
  *
  * @import "utilities/background-patterns";
  * @import "utilities/skew-transforms";
  */
