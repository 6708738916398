.c-usp {
    $c: &;

    @apply .relative .flex .text-0;

    &__icon {
        @apply .text-quaternary .mr-2.5 .pt-1.5;
    }

    &__text {
        @apply .text-sm .text-primary;
    }
}
