.c-button {
    $c: &;

    @apply .inline-flex .items-center .transition .px-6 .py-4.5 .leading-none;

    &__label {
        @apply .text-sm .font-bold .font-primary .text-white;
    }

    &--primary {
        @apply .bg-quaternary;

        &:hover,
        &:active {
            @apply .bg-quaternary-90;
        }
    }

    &--secondary {
        @apply .bg-primary;

        &:hover,
        &:active {
            @apply .bg-primary-90;
        }
    }
}
