.c-form {
    $c: &;

    @apply .text-0;

    &__rows {
    }

    &__row {
        @apply .mb-2;

        @screen md {
            @apply .flex .flex-wrap .-ml-8;
        }

        &:last-child {
            @apply .mb-0;

            #{$c} {
                &__column {
                    @apply .mb-0;
                }
            }
        }
    }

    &__column {
        @apply .text-0 .mb-2;

        @screen md {
            @apply .flex-1 .pl-8 .mb-0;
        }

        &:last-child {
            @apply .mb-0;

            @screen md {
                @apply .mb-2;
            }
        }
    }
}
