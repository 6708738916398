.c-usps {
    $c: &;

    @apply .relative;

    &__list {
        @apply .-mb-1;
    }

    &__item {
        @apply .pb-1;
    }
}
