.c-pagination {
    $c: &;

    &__number {
        @apply .bg-grey-lighter-30 .flex .items-center .justify-center .w-8 .h-8;

        &.is-active,
        &:hover,
        &:active {
            @apply .bg-primary;

            #{$c} {
                &__number-label {
                    @apply .text-white .font-bold;
                }
            }
        }
    }

    &__number-label {
        @apply .text-primary .text-sm;
    }
}
