h1,
h2,
h3,
h4,
h5 {
    @apply .font-primary .leading-normal .text-primary .font-normal;
}

h1 {
    @apply .text-2xl .font-light;

    @screen md {
        @apply .text-4xl;
    }
}

h2 {
    @apply .text-2.5xl .font-bold;
}

h3 {
    @apply .text-xl;
}

h4 {
    @apply .text-secondary .text-lg .font-bold;
}

h5 {
    @apply .text-base;
}

h1,
h2 {
    a {
        @apply .text-primary .font-bold .border-b .border-primary-30 .leading-tight;

        &:hover,
        &:active {
            @apply .border-primary-80;
        }
    }
}
