.c-social-feed {
    $c: &;

    &__list {
        @apply .flex .flex-wrap .-ml-8 .-mb-8;
    }

    &__item {
        @apply .w-full .pl-8 .pb-8;

        @screen md {
            @apply .w-1/2;
        }

        @screen lg {
            @apply .w-1/3;
        }
    }
}
