.c-secondary-menu {
    $c: &;

    @apply .text-0;

    &__item {
        @apply .inline-block .pl-5 .ml-5 .relative;

        &:before {
            top: 50%;
            left: 0;

            width: 1px;
            height: 12px;

            content: '';
            transform: translate(-50%, -50%);

            @apply .absolute .bg-grey-dark;
        }

        &:first-child {
            @apply .ml-0 .pl-0;

            &:before {
                @apply .hidden;
            }
        }
    }
}
