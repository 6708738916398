.c-icon {
    @apply .fill-current .transition;

    &--arrow {
        width: 4px;
        height: 12px;
    }

    &--arrow--alt {
        width: 3px;
        height: 6px;
    }

    &--phone {
        width: 12px;
        height: 16px;
    }

    &--download {
        width: 13px;
        height: 12px;
    }

    &--loop {
        width: 16px;
        height: 16px;
    }

    &--facebook {
        width: 8px;
        height: 16px;
    }

    &--instagram {
        width: 16px;
        height: 16px;
    }

    &--twitter {
        width: 16px;
        height: 16px;
    }

    &--linkedin {
        width: 17px;
        height: 16px;
    }

    &--youtube {
        width: 18px;
        height: 21px;
    }

    &--pinterest {
        width: 16px;
        height: 16px;
    }

    &--check {
        width: 8px;
        height: 7px;
    }

    &--check-alt {
        width: 13px;
        height: 10px;
    }
}
