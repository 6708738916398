.c-video {
    padding-bottom: 56.25%;

    $c: &;

    @apply .relative;

    iframe {
        width: 100% !important;
        height: 100% !important;

        @apply .absolute;
    }
}
