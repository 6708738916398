.c-mobile-nav {
    position: fixed;
    top: 0;
    right: -100%;

    width: 100%;
    height: 100%;

    opacity: 1;

    @apply .flex .bg-primary .z-20 .transition .p-10 .overflow-y-scroll;

    &__close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__nav {
        @apply .w-full .flex;
    }

    &__list {
        @apply .w-full;
    }

    &__item {
        @apply .pb-4 .mb-4 .border-b .border-white-20;
    }

    &.is-active {
        right: 0;

        @apply .transition;
    }

    .c-nav {
        @apply .block .h-auto;

        &--primary {
            .c-nav {
                &__list {
                    @apply .-mb-2 .ml-0 .h-auto;
                }

                &__item {
                    @apply .pb-2 .pl-0 .block .h-auto;

                    &.is-active {
                        .c-nav {
                            &__label {
                                @apply .text-white .border-white-30;
                            }
                        }
                    }
                }

                &__link {
                    &:hover,
                    &:active {
                        .c-nav {
                            &__label {
                                @apply .text-white;
                            }
                        }
                    }
                }

                &__label {
                    @apply .text-lg .text-white .font-normal .inline-block;
                }
            }
        }

        &--secondary {
            .c-nav {
                &__list {
                    @apply .-mb-2 .ml-0 .h-auto;
                }

                &__item {
                    @apply .pb-2 .pl-0 .block .h-auto;
                }

                &__label {
                    // @apply .text-lg .text-white .font-normal .inline-block;
                }
            }
        }
    }
}
